import { Link } from "gatsby";
import styled from "styled-components";
import colors from "../../styles/colors";

export const BlogPostContainer = styled(Link)`
    max-width: 50%;
    text-decoration: none;
    cursor: pointer;
    color: ${colors.primary};
    border-bottom: 1px solid ${colors.primary};
    margin-bottom: 16px;

    @media screen and (max-width: 720px) {
        max-width: 100%;
    }
`;

export const BlogParagraph = styled.p`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const BottomSheetContent = styled.div`
    padding: 16px;
`;

export const BottomSheetParagraph = styled.div`
    font-size: 24px;
    white-space: pre-line;

    @media screen and (max-width: 720px) {
        font-size: 20px;
    }
`;
