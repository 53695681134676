import * as React from 'react';
import { BottomSheetParagraph } from '../components/BlogPost/styles';
import Layout from "../components/Layout";
import { Content, H1, P } from '../styles';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const BlogPost = ({ pageContext }: any) => {
    return <Layout>
        <Content>
            <H1>{pageContext.post?.title}</H1>
            <BottomSheetParagraph dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(pageContext.post?.text?.raw)) }} />
        </Content>
    </Layout>
}

export default BlogPost;
